import React, { useEffect } from "react";
import { Wrapper, Toggle, Logo } from "./Header.styles";
import { Link } from "gatsby";

import Nav from "./nav";

const Header = (props) => {
  const {
    color,
    location,
    translations,
    setColor,
    menuState,
    dimensions,
    setMenuState,
  } = props;

  useEffect(() => {
    setMenuState(false);
    return () => {
      setMenuState(false);
    };
  }, [location]);

  useEffect(() => {
    if (menuState) {
      setColor(false);
    }
    return () => {};
  }, [menuState, setColor]);

  const lang = translations && translations[0]?.language.code;
  const uri = translations && translations[0]?.seo.slug;

  return (
    <Wrapper
      scolor={color ? true : false}
      isHome={location === "/" || location === "/en"}
      id="header"
    >
      <div className="container">
        <Logo scolor={color ? true : false}>
          <Link to={lang === "EN" ? "/" : "/en/"}>
            dev<span>studio</span>
          </Link>
        </Logo>
        <div className="col">
          <Nav
            setColor={setColor}
            menuState={menuState}
            setMenuState={setMenuState}
            scolor={color ? true : false}
            lang={lang}
            uri={location}
            setMenuState={setMenuState}
            dimensions={dimensions}
          />
          <ul className="menu-lang">
            <li className={lang === "EN" ? "active" : ""}>
              {lang === "EN" ? "PT" : <Link to={uri ? uri : "/"}>PT</Link>}
            </li>
            <li className={lang === "PT" ? "active" : ""}>
              {lang === "PT" ? "EN" : <Link to={uri ? uri : "/"}>EN</Link>}
            </li>
          </ul>
          <Toggle
            scolor={color ? true : false}
            onClick={() => setMenuState(!menuState)}
          >
            <span className="span span--top"></span>
            <span className="span span--center"></span>
            <span className="span span--bottom"></span>
          </Toggle>
        </div>
      </div>
    </Wrapper>
  );
};

export default Header;
