import { css } from "styled-components";
import { toRem, breakpoints } from "utils/mixins";

export const h1 = css`
  font-family: "Nexa";
  font-size: ${toRem(50)};
  font-weight: 300;
  margin: 0;
  margin-bottom: ${toRem(20)};
  line-height: normal;
  @media (orientation: portrait) {
    font-size: ${toRem(45)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(40)};
  }
`;

export const h2 = css`
  font-family: "Nexa";
  font-size: ${toRem(24)};
  font-weight: 300;
  @media ${breakpoints.bigDesktop} {
    font-size: ${toRem(24)};
  }
  @media (orientation: portrait) {
    font-size: ${toRem(24)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(24)};
  }
`;

export const h3 = css`
  font-family: "Nexa";
  font-size: ${toRem(18)};
  font-weight: 800;
  line-height: normal;
  @media (orientation: portrait) {
    font-size: ${toRem(18)};
  }
  @media (max-width: 599px) {
    font-size: ${toRem(18)};
  }
`;

export const h4 = css`
  font-family: "Nexa";
  font-size: ${toRem(18)};
  font-weight: 300;
  line-height: ${toRem(24)};
`;

export const p = css`
  font-family: "Open Sans", sans-serif;
  font-size: ${toRem(14)};
  line-height: ${toRem(20)};
`;
