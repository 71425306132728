export const white = {
  default: "#f5f5f5"
};

export const black = {
  default: "#373431"
};

export const blue = {
  default: "rgb(0, 0, 0)"
};

export const yellow = {
  default: "#fce300"
};

export const orange = {
  default: "#ffb838"
};

export const red = {
  default: "#da5959"
};

export const purple = {
  default: "#9185ae"
};
