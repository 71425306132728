import React from "react";
import { Helmet } from "react-helmet";

const CreateHead = (props) => {
  const headInfo = {
    title: props.title ? props.title : "devstudio",
    description: props.description
      ? props.description
      : "A devstudio é uma agência de design do Porto. Focados no processo criativo e a sua aplicação no mundo real.",
    url: props.url ? props.url : "https://devstudio.com.pt",
    image: props.image
      ? props.image.localFile.childImageSharp.original.src
      : "https://devstudio.com.pt/banner.png",
  };

  return (
    <Helmet>
      <html lang={props.lang === "PT" ? "pt" : "en"} amp />
      <title>{headInfo.title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={headInfo.description} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
      <meta property="og:url" content={headInfo.url} />
      <meta property="og:title" content={headInfo.title} />
      <meta property="og:description" content={headInfo.description} />
      <link rel="image_src" href={headInfo.image}></link>
      <meta property="og:image" content={headInfo.image} />
      <meta property="og:site_name" content={headInfo.title} />
      <meta name="twitter:title" content={headInfo.title} />
      <meta name="twitter:description" content={headInfo.description} />
      <meta name="twitter:image" content={headInfo.image} />
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:image:alt" content={headInfo.title}></meta>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap"
        rel="stylesheet"
      />
      <link href="/css/main.css" rel="stylesheet" />

      <script>
        {`
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '508387304038121');
fbq('track', 'PageView');`}
      </script>
      <noscript>
        {`<img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=508387304038121&ev=PageView&noscript=1"
        />`}
      </noscript>
      {props.children}
    </Helmet>
  );
};
export default CreateHead;
